import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {
  private apiUrl: string = '';

  constructor(
    private http: HttpClient,
  ) { }

  // Método para realizar una solicitud POST al API
  public post(endpoint: string, data: any, autenticado: boolean, apiInvoke: string): Observable<any> {
    let headers;

    if (apiInvoke === 'Club') {
      this.apiUrl = environment.apiUrl;

      if (autenticado) {
        headers = new HttpHeaders ({
          'Token': sessionStorage.getItem('token')!
        });
      } else {
        headers = new HttpHeaders ({
          'keyceb': 'ceb123ceb123'
        });
      }
    } else if (apiInvoke === 'ConsExternas'){
      this.apiUrl = environment.apiUrlConsExternas;

      headers = new HttpHeaders ({
        // 'apiKey': 'TmAC06Tm2023'
        'apiKey': '123456'
      })
    }

    const url = `${this.apiUrl}/${endpoint}`;
    return this.http.post(url, data, { headers });
  }
}
