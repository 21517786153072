<div class="width-full bg-gray contenedor">
  <mat-card class="width-50porc">
    <mat-card-header class="contenedor">
      <img src="./assets/img/ClubEstarBienIcon.png" width="250" height="200">
    </mat-card-header>
    <mat-card-content class="margin-2rem">
      <form [formGroup]="validadorForm">
        <div fxLayout="column" fxLayoutGap="20px">

          <mat-form-field appearance="outline">
            <mat-label>Aliados Comerciales</mat-label>
            <input type="text" matInput [formControl]="aliados" [matAutocomplete]="autoAliado">
            <mat-autocomplete #autoAliado="matAutocomplete" [displayWith]="displayFnAliado"
              (optionSelected)="onOptionSelected($event)">
              <mat-option *ngFor="let aliado of aliadosFiltrados | async" [value]="aliado">
                {{ aliado.nombre_aliado }}
              </mat-option>
            </mat-autocomplete>
            <mat-icon matSuffix>group</mat-icon>
            <mat-error *ngIf="aliados.hasError('required') || aliados.touched">
              Debes seleccionar el Aliado Asociado.
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Documento de Identidad</mat-label>
            <input matInput formControlName="documento_identidad" placeholder="Número de Documento de Identidad"
              (input)="limitarDigitos($event)">
            <mat-icon matSuffix>portrait</mat-icon>
            <mat-error *ngIf="validadorForm.get('documento_identidad')?.invalid ||
              validadorForm.get('documento_identidad')?.touched">
              Este campo es Requerido.
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Código de Descuento</mat-label>
            <input matInput formControlName="codigo_descuento" placeholder="Código de Descuento" (input)="limitarDigitos($event)">
            <mat-icon matSuffix>local_offer</mat-icon>
            <mat-error *ngIf="validadorForm.get('codigo_descuento')?.invalid ||
              validadorForm.get('codigo_descuento')?.touched">
            Este campo es Requerido.
          </mat-error>
          </mat-form-field>

          <div *ngIf="mostrarMensajeError" class="contenedor font-24px">
            <mat-icon color="warn">error_outline</mat-icon>
            <span class="color-red">{{ mensajeError }}</span>
          </div>

          <div *ngIf="mostrarRespuesta" class="contenedor font-16px margin-left-5vh" fxLayout="column" fxLayoutAlign="start">
            <div>
              <mat-icon>person</mat-icon>
              Nombre Afiliado: <span class="color-blue">{{ nombreAfiliado }}</span>
            </div>
            <div>
              <mat-icon>check</mat-icon>
              Estado Afiliado: <span class="color-blue">{{ statusAfiliado }}</span>
            </div>
            <div>
              <mat-icon>card_membership</mat-icon>
              Plan Activo: <span class="color-blue">{{ convenio }}</span>
            </div>
          </div>

        </div>
      </form>
    </mat-card-content>
    <mat-card-actions class="contenedor" fxLayoutGap="20px">
      <button mat-raised-button color="warn" (click)="limpiarCampos()" class="margin-10vh">Limpiar Campos</button>
      <button mat-raised-button color="primary" (click)="verificarCodigo()" class="margin-10vh">Verificar</button>
    </mat-card-actions>
    <div class="contenedor color-gray margin-10vh">
      <mat-icon>copyright</mat-icon>2023 TCS - Technology Consulting Solution
    </div>
  </mat-card>
</div>
