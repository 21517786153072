import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { Observable, map, startWith } from 'rxjs';
import { ApiServiceService } from 'src/app/core/services/api-service.service';
import { Aliado } from 'src/app/shared/models/aliado';

@Component({
  selector: 'app-validador-ceb',
  templateUrl: './validador-ceb.component.html',
  styleUrls: ['./validador-ceb.component.scss']
})

export class ValidadorCEBComponent implements OnInit {
  validadorForm!: FormGroup;
  listaAliados: Aliado[] = [];
  mostrarMensajeError: boolean = false;
  mostrarRespuesta: boolean = false;
  mensajeError: string = '';
  nombreAfiliado: string = '';
  documento_identidad: string = '';
  convenio: string = '';
  statusAfiliado: string = '';

  @ViewChild('autoAliado')
  autoAliado!: MatAutocomplete;

  aliados = new FormControl<string | Aliado>('', [Validators.required]);

  aliadosFiltrados!: Observable<Aliado[]>;

  url: string = '';

  ngOnInit(): void {
    this.consultarAliados();
    this.validadorForm = this.fb.group ({
      aliados: ['', Validators.required],
      codigo_descuento: ['', Validators.required],
      documento_identidad: ['', Validators.required]
    });

    setTimeout(() => {
      this.aliadosFiltrados = this.aliados.valueChanges.pipe(
        startWith(''),
        map(value => {
          const nombre_aliado = typeof value === 'string' ? value : value?.nombre_aliado;
          return nombre_aliado ? this._filterAliados(nombre_aliado as string) : this.listaAliados.slice();
        }),
      );
    }, 1000);
  }

  constructor(
    private fb: FormBuilder,
    private apiService: ApiServiceService
  ) {}

  consultarAliados() {
    this.url = '/aliados';

    this.apiService.post(this.url,null,false,'Club').subscribe(response => {
      if (response) {
        this.listaAliados = response
      }
    })
  }

  displayFnAliado(aliado: Aliado): string {
    return aliado && aliado.nombre_aliado ? aliado.nombre_aliado : '';
  }

  private _filterAliados(name: string): Aliado[] {
    const filterValue = name.toLowerCase();

    return this.listaAliados.filter(option => option.nombre_aliado.toLowerCase().includes(filterValue));
  }

  limitarDigitos(event: any) {
    const input = event.target as HTMLInputElement;
    let valor = input.value;

    valor = valor.replace(/\D/g, '');

    if (valor.length > 8) {
      valor = valor.slice(0, 8);
    }

    input.value = valor;
  }

  onOptionSelected(event: any) {
    const aliado = event.option.value;
    this.validadorForm.get('aliados')?.setValue(aliado); // Actualiza el valor del control 'aliados'
  }

  verificarCodigo() {
    this.url = 'validateCodeAuth';

    let data = {
      codeGenerated: this.validadorForm.value.codigo_descuento,
      documentId: this.validadorForm.value.documento_identidad,
      sucursal: this.validadorForm.value.aliados.nombre_aliado
    }
    // debugger;
    this.apiService.post(this.url,data,false,'ConsExternas').subscribe(response => {
      if (response) {
        if (response.code !== '200') {
          this.mostrarMensajeError = true;
          this.mensajeError = response.data;
          this.mostrarRespuesta = false;
        } else {
          this.url = 'getUserStatus';
          let data = {
            docIdentidad: this.validadorForm.value.documento_identidad
          }
          this.apiService.post(this.url,data,false,'ConsExternas').subscribe(response => {
            if (response) {
              this.mostrarRespuesta = true;
              this.mostrarMensajeError = false;
              this.nombreAfiliado = response.data[0].nombre_completo;
              this.documento_identidad = response.data[0].doc_identidad;
              this.convenio = response.data[0].convenio;
              this.statusAfiliado = response.data[0].status;
            }
          })
        }
      }
    })
  }

  limpiarCampos(){
    this.validadorForm.get('codigo_descuento')?.reset();
    this.validadorForm.get('documento_identidad')?.reset();
    this.autoAliado.options.forEach(option => option.deselect());
    this.aliados.reset();
    this.mensajeError = '';
    this.nombreAfiliado = '';
    this.statusAfiliado = '';
    this.convenio = '';
    this.mostrarMensajeError = false;
    this.mostrarRespuesta = false;
  }

}
